import { render, staticRenderFns } from "./CustomerNotificationStepThree.vue?vue&type=template&id=479485c1&scoped=true&"
import script from "./CustomerNotificationStepThree.vue?vue&type=script&lang=js&"
export * from "./CustomerNotificationStepThree.vue?vue&type=script&lang=js&"
import style0 from "./CustomerNotificationStepThree.vue?vue&type=style&index=0&id=479485c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479485c1",
  null
  
)

export default component.exports